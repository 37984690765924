<template>
  <div class="home">
    <eden-page-header :title="'Directory'">
      <template slot="actions">
        <div class="page--head-actions is-flex is-flex-wrap align-center">
          <el-button type="primary" @click="add">
            Add {{ tabs[tab] }}
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <eden-page-stats :title="'Overview'" :stats="stats" />
    <el-tabs v-model="tab" @tab-click="updateRouteQuery">
      <el-tab-pane label="Rider Directory" name="riders">
        <riders @success="getSummary" />
      </el-tab-pane>
      <el-tab-pane label="Company Directory" name="companies">
        <companies />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Riders from "@/components/MarketplaceLogistics/Directory/Riders/Riders.vue";
import Companies from "@/components/MarketplaceLogistics/Directory/Companies/Companies.vue";
import riders from "../../../requests/marketplace-logistics/riders";

export default {
  name: "Directory",
  components: {
    Riders,
    Companies,
  },
  data() {
    return {
      tab: "riders",
      tabs: {
        riders: "Rider",
        companies: "Company",
      },
      summary: [],
    };
  },
  computed: {
    stats() {
      return [
        {
          label: "Riders (Active)",
          figure: this.summary.active_riders_count,
        },
        {
          label: "Logistics Companies",
          figure: this.summary.logistics_companies_count,
        },
      ];
    },
  },
  created() {
    const { t } = this.$route.query;
    this.tab = t ? t : "riders";
    this.getSummary();
  },
  methods: {
    updateRouteQuery(tab) {
      const currentTab = this.$route.query.t;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "marketplace-logistics.directory.index",
        query: { t: tab.name },
      });
    },
    add() {
      const tab = this.tabs[this.tab].toLowerCase();
      this.$router.push({ name: `marketplace-logistics.directory.${tab}-add` });
    },
    async getSummary() {
      try {
        const res = await riders.summary();
        this.summary = res.data.data;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
};
</script>
