<template>
  <section>
    <eden-table-actions
      :title="`${riders?.data?.length || 0} Riders`"
      @search="searchOrders"
    >
      <template slot="actions">
        <RiderFilter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
          class="mr-2"
        />
        <RidersExport :data="riders?.data" />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        class="ml-auto"
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table
        :data="riders.data"
        empty-text="No Products"
        v-if="riders?.data?.length"
      >
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Name</span>
            </div>
          </template>
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'marketplace-logistics.directory.rider',
                params: { id: scope.row.uuid },
              }"
            >
              <span class="font-sm text-primary">
                {{ formatFullName(scope.row) }}</span
              ></router-link
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Status</span>
            </div>
          </template>
          <template slot-scope="scope">
            <el-tag :type="setType(scope.row.status)">
              <span>{{ formatText(scope.row.status) }}</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header"> Phone Number </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatPhoneNumber(scope.row.phone_number) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header"> Email address</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ scope.row.email }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header"> Logistics Company</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ scope.row.company.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'vendor_manager',
              'operations',
            ])
          "
          min-width="60"
        >
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    action: 'edit',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Edit rider</el-dropdown-item
                >

                <el-dropdown-item
                  :command="{
                    action: 'status',
                    id: scope.row.uuid,
                    rider: scope.row,
                    index: scope.$index,
                  }"
                  >Change Rider Status</el-dropdown-item
                >

                <el-dropdown-item
                  :command="{
                    action: 'delete',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  class="text-danger"
                  >Delete rider</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Riders'" />
      <eden-pagination
        v-if="riders?.data?.length"
        :from="riders.meta.from"
        :to="riders.meta.to"
        :total="riders.meta.total"
        :current-page.sync="page"
        :page-size="riders.meta.per_page"
      />
    </template>

    <RiderDelete
      :show.sync="rider.visibility"
      :rider-id="riderId"
      @success="getRiders"
    />
    <RiderStatus
      :show.sync="showStatus"
      :rider="singleRider"
      @success="getRiders"
    />
  </section>
</template>

<script>
import riders from "@/requests/marketplace-logistics/riders";
import RiderDelete from "./Actions/RiderDelete.vue";
import RiderStatus from "./Actions/RiderStatus.vue";
import RidersExport from "./RidersExport.vue";
import RiderFilter from "./RiderFilter.vue";
export default {
  name: "Riders",
  components: { RiderDelete, RiderStatus, RidersExport, RiderFilter },
  data() {
    return {
      riders: [],
      loading: false,
      searchQuery: "",
      page: 1,
      rider: {
        action: "add",
        visibility: false,
        data: {},
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      riderId: null,
      showStatus: false,
      singleRider: {},
      status: "",
      company_id: null,
    };
  },
  created() {
    this.getRiders();
  },
  methods: {
    command(command) {
      this.riderId = command.id;
      this.singleRider = command.rider;
      switch (command.action) {
        case "add":
          this.rider.action = "add";
          break;
        case "edit":
          this.rider.action = "edit";
          this.edit();
          break;
        case "status":
          this.rider.action = "status";
          this.showStatus = true;
          break;
        case "delete":
          this.rider.action = "delete";
          this.rider.visibility = true;
          break;
      }
    },
    edit() {
      this.$router.push({
        name: "marketplace-logistics.directory.rider-edit",
        params: { id: this.riderId },
      });
    },
    async getRiders() {
      this.loading = true;
      const params = {
        name: this.searchQuery,
        status: this.status,
        company_id: this.company_id,
      };
      await riders
        .list(params)
        .then((response) => {
          if (response.data.status) {
            this.riders = response.data;
            this.$emit("success");
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchOrders(query) {
      this.searchQuery = query;
      this.page = 1;
      this.getRiders();
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      this.status = params.status;
      this.company_id = params.company;
      this.filterParams = {
        status: true,
        params: params,
        paramsLabel: paramsLabel,
      };
      this.page = 1;
      this.getRiders();
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.status = "";
      this.company_id = null;
      this.getRiders();
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
