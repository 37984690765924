<template>
  <div>
    <eden-filter
      :width="270"
      :properties.sync="properties"
      :disabled="disabled"
      :loading="loading"
      @open="getCompanies"
      @reset="reset"
      @filter="filter"
    />
  </div>
</template>

<script>
import companies from "@/requests/logistics/directory/companies";
export default {
  name: "RiderFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        logisticsCompany: {
          key: "company",
          label: "Logistics Company",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        status: {
          key: "status",
          label: "Status",
          type: "list",
          options: [
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
          ],
          value: [],
          searchable: true,
        },
      },
      companies: [],
    };
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getCompanies() {
      companies
        .index(1)
        .then((response) => {
          if (response.data.status) {
            this.companies = response.data.data;
            this.setCompanies();
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setCompanies() {
      this.properties.logisticsCompany.options = this.companies.map(
        (company) => {
          return {
            label: this.formatText(company.name),
            value: company.id,
          };
        },
      );
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });
      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
