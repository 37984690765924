var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('eden-table-actions',{attrs:{"title":`${_vm.riders?.data?.length || 0} Riders`},on:{"search":_vm.searchOrders}},[_c('template',{slot:"actions"},[_c('RiderFilter',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"clear":_vm.filterParams.clear},on:{"filter":_vm.filter}}),_c('RidersExport',{attrs:{"data":_vm.riders?.data}})],1)],2),(_vm.loading)?_c('eden-loader'):[(_vm.filterParams.status)?_c('eden-filter-items',{staticClass:"ml-auto",attrs:{"params":_vm.filterParams.paramsLabel},on:{"clear-filter":_vm.clearFilter,"clear-filters":_vm.clearFilters}}):_vm._e(),(_vm.riders?.data?.length)?_c('el-table',{attrs:{"data":_vm.riders.data,"empty-text":"No Products"}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'marketplace-logistics.directory.rider',
              params: { id: scope.row.uuid },
            }}},[_c('span',{staticClass:"font-sm text-primary"},[_vm._v(" "+_vm._s(_vm.formatFullName(scope.row)))])])]}}],null,false,497184258)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Name")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":_vm.setType(scope.row.status)}},[_c('span',[_vm._v(_vm._s(_vm.formatText(scope.row.status)))])])]}}],null,false,546397399)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Status")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.formatPhoneNumber(scope.row.phone_number))+" ")])]}}],null,false,1868196605)},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Phone Number ")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(scope.row.email)+" ")])]}}],null,false,866748460)},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Email address")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(scope.row.company.name)+" ")])]}}],null,false,973505998)},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Logistics Company")])])],2),(
          _vm.allowAccessFor([
            'superadmin',
            'admin',
            'vendor_manager',
            'operations',
          ])
        )?_c('el-table-column',{attrs:{"min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                  action: 'edit',
                  id: scope.row.uuid,
                  index: scope.$index,
                }}},[_vm._v("Edit rider")]),_c('el-dropdown-item',{attrs:{"command":{
                  action: 'status',
                  id: scope.row.uuid,
                  rider: scope.row,
                  index: scope.$index,
                }}},[_vm._v("Change Rider Status")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{
                  action: 'delete',
                  id: scope.row.uuid,
                  index: scope.$index,
                }}},[_vm._v("Delete rider")])],1)],1)]}}],null,false,3020470691)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No Riders'}}),(_vm.riders?.data?.length)?_c('eden-pagination',{attrs:{"from":_vm.riders.meta.from,"to":_vm.riders.meta.to,"total":_vm.riders.meta.total,"current-page":_vm.page,"page-size":_vm.riders.meta.per_page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],_c('RiderDelete',{attrs:{"show":_vm.rider.visibility,"rider-id":_vm.riderId},on:{"update:show":function($event){return _vm.$set(_vm.rider, "visibility", $event)},"success":_vm.getRiders}}),_c('RiderStatus',{attrs:{"show":_vm.showStatus,"rider":_vm.singleRider},on:{"update:show":function($event){_vm.showStatus=$event},"success":_vm.getRiders}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }