<template>
  <div>
    <eden-table-actions :title="title" @search="searchCompanies" />
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <!-- <pre>{{ pageData }}</pre> -->
        <el-table :data="pageData">
          <el-table-column width="220">
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'marketplace-logistics.directory.company',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatName(scope.row.name) }}</span
                ></router-link
              >
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Email Address</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.contact_email }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Active Riders</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.active_marketplace_riders_count }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="allowAccessFor(['admin', 'operations', 'vendor_manager'])"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="command">
                <span class="el-dropdown-link more">
                  <i class="eden-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="{
                      action: 'edit',
                      id: scope.row.id,
                      index: scope.$index,
                    }"
                    >Edit Company</el-dropdown-item
                  >
                  <el-dropdown-item
                    :command="{
                      action: 'delete',
                      id: scope.row.id,
                      company: scope.row,
                      index: scope.$index,
                    }"
                    class="text-danger"
                    >Delete company</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
    <CompanyDelete
      :show.sync="company.visibility"
      :company="companyList"
      @success="getCompanies"
    />
  </div>
</template>

<script>
import companies from "@/requests/logistics/directory/companies";
import * as actions from "@/store/action-types";
import CompanyDelete from "../../../Logistics/Directory/Companies/Company/Actions/CompanyDelete.vue";

export default {
  name: "Companies",
  props: {
    totalCompanies: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      company: {
        action: "add",
        visibility: false,
        data: {},
      },
      companyId: null,
      companyList: {},
    };
  },
  computed: {
    companies() {
      return this.$store.getters.directory.companies;
    },
    from() {
      return this.companies.pages[this.page].from;
    },
    to() {
      return this.companies.pages[this.page].to;
    },
    total() {
      return this.companies.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Companies`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.companies.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getCompanies();
      }
    },
  },
  created() {
    this.getCompanies();
  },
  methods: {
    command(command) {
      this.companyId = command.id;
      this.companyList = command.company;
      switch (command.action) {
        case "edit":
          this.company.action = "edit";
          this.edit();
          break;
        case "delete":
          this.company.action = "delete";
          this.company.visibility = true;
          break;
      }
    },
    getCompanies() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_LOGISTICS_DIRECTORY, {
          directory: "companies",
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchCompanies(query) {
      if (query === "") {
        this.setPageData();
        return;
      }
      this.loading = true;
      companies
        .search(query)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = data.data;
            this.showPagination = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.companies.pages[this.page].data;
      this.setTotalCompanies = this.total;
      this.showPagination = true;
    },
    edit() {
      this.$router.push({
        name: "marketplace-logistics.directory.company-edit",
        params: { id: this.companyId },
      });
    },
  },
  components: { CompanyDelete },
};
</script>
