var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.searchCompanies}}),(_vm.loading)?_c('eden-loader'):[(_vm.pageData.length)?[_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'marketplace-logistics.directory.company',
                params: { id: scope.row.id },
              }}},[_c('span',{staticClass:"font-sm text-primary"},[_vm._v(" "+_vm._s(_vm.formatName(scope.row.name)))])])]}}],null,false,3808167959)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Name")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(scope.row.contact_email)+" ")])]}}],null,false,2727731219)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Email Address")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(scope.row.active_marketplace_riders_count)+" ")])]}}],null,false,1997429300)},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Active Riders")])])])],2),(_vm.allowAccessFor(['admin', 'operations', 'vendor_manager']))?_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link more"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                    action: 'edit',
                    id: scope.row.id,
                    index: scope.$index,
                  }}},[_vm._v("Edit Company")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{
                    action: 'delete',
                    id: scope.row.id,
                    company: scope.row,
                    index: scope.$index,
                  }}},[_vm._v("Delete company")])],1)],1)]}}],null,false,1384716979)}):_vm._e()],1),(_vm.showPagination)?_c('eden-pagination',{attrs:{"from":_vm.from,"to":_vm.to,"total":_vm.total,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()]:_c('eden-content-empty',{attrs:{"text":'No data'}})],_c('CompanyDelete',{attrs:{"show":_vm.company.visibility,"company":_vm.companyList},on:{"update:show":function($event){return _vm.$set(_vm.company, "visibility", $event)},"success":_vm.getCompanies}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }