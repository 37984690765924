<template>
  <eden-confirm-dialog
    :title="'Delete Company'"
    :button-text="'Delete'"
    :button-type="'danger'"
    :button-status.sync="deleting"
    :show.sync="setShow"
    @confirm="deleteCompany"
  >
    <p>
      This action cannot be undone. Are you sure you want to delete,
      <span class="text-grey-primary text-bold">{{ company.name }}</span
      >?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import companies from "@/requests/logistics/directory/companies";

export default {
  name: "CompanyDelete",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    company: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteCompany() {
      this.deleting = true;
      const payload = {
        company_id: this.company.id,
      };
      companies
        .delete(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.$message.error("Cannot delete companies with riders");
            return;
          }
          const errorMessage = error.response.data;
          this.$message.error(errorMessage.message);
        })
        .finally(() => {
          this.close();
        });
    },
    close() {
      this.setShow = false;
      this.deleting = false;
    },
  },
};
</script>
